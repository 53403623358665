import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import {Add, Remove} from '@material-ui/icons';
import CoachingKangaroo from '../../../../api/CoachingKangaroo';
import {CircularProgress} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const renderContext = (contextItem) => {
    return (
        <Grid
            container
            justify="flex-end"
            alignItems="flex-end"
            key={Math.random()}
        >
            <Grid item xs={8}>{`${contextItem.name} : ${contextItem.text}`}</Grid>
            <Grid item xs={3}/>
        </Grid>
    );
};

const ConversationItemContext = (props) => {
    const [openContext, setOpenContext] = useState(false);
    const [context, setContext] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [defaultContext, setDefaultContext] = useState([])
    const {t} = useTranslation();

    useEffect(() => {
        CoachingKangaroo.get(
            '/api/v1/training/' +
            encodeURIComponent(props.project)+
            '/default-contexts'
        ).then((response) => {
            setDefaultContext(response.data)
        }).catch((error) => {
            setDefaultContext([])
            console.error('Error while putting data', error);
        });
        CoachingKangaroo.get(
            '/api/training/v1/' +
            encodeURIComponent(props.project) +
            '/context/' +
            encodeURIComponent(props.answerId)
        )
            .then((response) => {
                setContext(response.data);
                setDataFetched(true);
            })
            .catch((error) => {
                console.error('Error while putting data', error);
            });
    }, [props.answerId, props.project]);

    return (
        <React.Fragment>
            {openContext ? (
                <Grid container justify="flex-end" alignItems="flex-end">
                    <Grid item xs={8}>
                        <Button
                            size="small"
                            startIcon={<Remove/>}
                            onClick={() => setOpenContext(false)}
                        >
                            {t('Close context')}
                        </Button>
                        <div>
                            {!dataFetched && <CircularProgress size={20}/>}
                            {dataFetched && context.length === 0 && (
                                <span>{t('No context')}</span>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={3}/>
                </Grid>
            ) : (
                <Grid container justify="flex-end" alignItems="flex-end">
                    <Grid item xs={8}>
                        <Button
                            size="small"
                            startIcon={<Add/>}
                            onClick={() => {
                                setOpenContext(!openContext);
                            }}
                        >
                            {t('Display context')}
                        </Button>
                    </Grid>
                    <Grid item xs={3}/>
                </Grid>
            )}

            {context.length > 0 ? (
                <>
                    <Collapse in={true}>
                        {context.map((contextItem) => {
                            if (defaultContext.includes(contextItem.name)) return renderContext(contextItem)
                            else return null;
                        })}
                    </Collapse>
                    <Collapse in={openContext}>
                        {context.map((contextItem) => {
                            if (defaultContext.includes(contextItem.name)) {
                                return null
                            } else {
                                return renderContext(contextItem);
                            }

                        })}
                    </Collapse>
                </>
            ) : null}
        </React.Fragment>
    );
};
export default ConversationItemContext;
